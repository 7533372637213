<template>
    <van-overlay :show="requestShow" @click="requestShow = false">
      <div @click.stop>
        <div class="content">
          <div class="x-close-dialog">
            <van-image width="20px" @click="requestShow=false" :src="require('@/assets/img/x.png')">
            </van-image>
          </div>
          <div class="content-img">
            <van-image :src="require('@/assets/img/successApply.png')"></van-image>
          </div>
          <div class="text-father">
            <p>请稍后，正在审核中......</p>
            <p class="text-p1">审核通过后</p>
            <p class="text-p2">服务人员会联系您，请保持手机畅通~</p>
          </div>
          <div class="button-father">
            <van-button round type="info" @click="toServiceOrder">
              查看服务单</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </template>

<script>
export default {
  name: "request",
  data() {
    return {
      requestShow: true
    }
  },
  methods: {
    toServiceOrder() {
      this.$router.push({path: '/older-service-request'})
    }
  }

}
</script>

<style scoped>
.content {
  height: 9.8rem;
  width: 8rem;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
}
.content-img {
  width: 3rem;
  margin: 0.2rem auto;
}
.x-close-dialog {
  font-size: 0.6rem;
  text-align: right;
  padding: 0.3rem 0.4rem 0 0
}
.text-father {
  font-size: 0.5rem;
  font-weight: bold;
  display: inline-block;
  width: 100%;
  text-align: center;
  line-height: 0.8rem;
  margin-top: 0.5rem;
}
.text-p1 {
  font-size: 0.4rem;
  color: red;
}
.text-p2 {
  font-size: 0.4rem;
}
.button-father {
  font-size: 16px;
  text-align: center;
  margin-top: 0.8rem;
}
</style>
